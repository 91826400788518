.sidebar {
  position: fixed;
}
.nav-Icons {
  font-size: 16px;
  margin-right: 20px;
  position: absolute;
  left: 20px;
  top: 15px;
  line-height: 1.25;
  height: 16px;
}
.rs-sidenav-default {
  height: 100vh;
  position: fixed;
  overflow-y: scroll !important;
  white-space: "nowrap";
  width: 16% !important;
  background-color: #ffcede !important;
}
.logo-img {
  width: 160px;
}
.rs-sidenav-default .rs-sidenav-item,
.rs-sidenav-default .rs-dropdown-toggle,
.rs-sidenav-default .rs-sidenav-toggle-button {
  background-color: transparent !important;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.rs-sidenav-default .rs-sidenav-item:hover {
  background-color: #fff !important;
  color: #ff518a !important;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
}
#active {
  background-color: #fff !important;
  color: #ff518a !important;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
}

:where(.css-dev-only-do-not-override-f7vrd6).ant-upload-wrapper
  .ant-upload-drag {
  height: auto !important;
}
.rs-nav {
  margin-top: 1rem !important;
}
.head-home h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 0px !important;
}
.head-home h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px !important;
}
.head-home h2 span {
  font-size: 16px;
  font-weight: 400;
}
.css-15wwp11-MuiTableHead-root {
  background-color: #ffedf3 !important;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}
.css-1ygcj2i-MuiTableCell-root {
  border-bottom: none !important;
  padding: 7px !important;
}
.css-1ex1afd-MuiTableCell-root {
  border-bottom: none !important;
  padding: 7px !important;
}
.table-outline {
  border: 1px solid #ff518a;
  padding: 5px;
  border-radius: 5px;
}
.table-img {
  width: 50px;
}
.table-row {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.total-box-color {
  background-color: #ffedf3;
  border-radius: 5px;
  padding: 1rem;
}
.total-box h3 {
  font-size: 20px;
  font-weight: 600;
}
.total-box p {
  font-size: 16px;
  font-weight: 600;
}
.total-box p span {
  font-size: 16px;
  font-weight: 600;
  background-color: #deffce;
  border-radius: 10px;
  padding: 3px;
}
.totalern-outline {
  border: 1px solid #ff518a;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 25px;
  font-weight: 700;
}
.label {
  font-size: 16px;
  font-weight: 500;
}
.input {
  width: 100% !important;
}
.apply-btn {
  background-color: #ff518a !important;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 3px 30px;
}
.rating {
  color: #ca2727;
}
.ant-table-thead {
  background-color: #ffedf3 !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background-color: transparent !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper .ant-table-cell,
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper tfoot > tr > th,
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper tfoot > tr > td {
  padding: 10px 10px !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background-color: #fff !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #ff518a !important;
  border-color: #ff518a !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-pagination
  .ant-pagination-item-active {
  border-color: #ff518a !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-f7vrd6).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: #ff518a !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-f7vrd6).ant-input-outlined:focus-within {
  border-color: #ff518a !important;
  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-input-outlined:hover {
  border-color: #ff518a !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #ff518a !important;
  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-f7vrd6).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  border-bottom: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-checkbox-indeterminate
  .ant-checkbox-inner:after {
  background-color: #ff518a !important;
}
.download-btn {
  background-color: #000 !important;
  padding: 4px 30px !important;
}
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #ffcede !important;
  color: #000 !important;
}
.total-H {
  font-size: 16px;
  font-weight: 700;
}
.total-H span {
  font-size: 16px;
  font-weight: 400;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-upload-wrapper
  .ant-upload-drag:not(.ant-upload-disabled):hover,
:where(.css-dev-only-do-not-override-f7vrd6).ant-upload-wrapper
  .ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #ff518a !important;
}
.product-upload {
  padding-left: 2rem !important;
}
@media (max-width: 992px) {
  .product-upload {
    padding-left: 10px !important;
  }
}
.submit-btn {
  background-color: #ff518a !important;
  padding: 5px 35px !important;
}
.remove-btn {
  background-color: #ff518a !important;
  width: 100% !important;
  border-radius: 0px !important;
}
.total {
  font-size: 16px;
  font-weight: 600;
}
.totalcon {
  font-size: 16px;
  font-weight: 400;
}
.table-box {
  border: 1px solid #ff518a;
  box-shadow: 0px 0px 9px 1px #ff518a;
  border-radius: 5px;
}
.table-box2 {
  border: 1px solid #000;
  border-radius: 5px;
}
.datepicker {
  width: 100%;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-picker-outlined:hover {
  border-color: #ff518a !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-picker-range
  .ant-picker-active-bar {
  background-color: #ffcede !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-picker-outlined:focus,
:where(.css-dev-only-do-not-override-f7vrd6).ant-picker-outlined:focus-within {
  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #ff518a !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-f7vrd6).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-f7vrd6).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #ff518a !important;
}
.percent-bg {
  background-color: #deffce;
  border-radius: 10px;
  padding: 2px 5px;
}
.percent-bgdrop {
  background-color: #ff8a8a;
  border-radius: 10px;
  padding: 2px 5px;
}
.home {
  background-color: #ffedf3 !important;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 170px !important;
}
.coupon {
  background-color: #ffcede !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 170px !important;
}
.css-1d9bq45-MuiButtonGroup-root .MuiButtonGroup-firstButton,
.css-1d9bq45-MuiButtonGroup-root .MuiButtonGroup-middleButton {
  border: none !important;
}
.img-view {
  border: 1px solid #ff518a;
  cursor: pointer;
  width: 200px !important;
}
.img-view img {
  width: 100%;
}
.edit-icon {
  cursor: pointer;
  display: flex;
  align-items: end;
}

.banner-title {
  font-size: 16px;
  font-weight: 600;
  padding: 16px 0px;
  margin-bottom: 0px;
}
.banner-input {
  width: 100%;
  border: 1px solid #000;
  padding: 4px;
}
.banner-input-3 {
  width: 83% !important;
  border: 1px solid #000;
  padding: 4px;
}
.input-coupon {
  width: 220px !important;
}
.update-btn {
  background-color: #ff518a !important;
}
:where(
    .css-dev-only-do-not-override-f7vrd6
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-f7vrd6
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-f7vrd6
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-f7vrd6
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item::before {
  opacity: 1 !important;
}
:where(
    .css-dev-only-do-not-override-f7vrd6
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions,
:where(
    .css-dev-only-do-not-override-f7vrd6
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions,
:where(
    .css-dev-only-do-not-override-f7vrd6
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions,
:where(
    .css-dev-only-do-not-override-f7vrd6
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions {
  opacity: 1 !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action {
  opacity: 1 !important;
}
.custom {
  cursor: pointer;
  color: #2c48a9;
  text-decoration: none;
}
.title-h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
}
.upload {
  cursor: pointer;
  color: #000;
  text-decoration: none;
}
.rs-nav a {
  text-decoration: none !important;
  color: #000 !important;
}
.sidebar .rs-nav-item-active {
  background-color: #f0f0f0; /* Change this to your desired color */
  color: #000; /* Optional: Change the text color if needed */
}

.sidebar .rs-nav-item-active a {
  color: inherit; /* Ensure the link color inherits from the parent */
}
.forgetpass {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #000;
}
.log-btn button {
  background-color: #ff518a !important;
  width: 60% !important;
  height: 45px !important;
  border-radius: 50px !important;
}
.log-btn p {
  font-size: 16px;
  font-weight: 400;
  color: #555555;
  padding-top: 10px;
}
.log-btn p a {
  font-size: 16px;
  font-weight: 400;
  color: #ff518a !important;
  padding-top: 10px;
  text-decoration: underline !important;
  cursor: pointer;
}
.login-box {
  width: 80%;
}
@media (max-width: 768px) {
  .login-box {
    width: 100%;
  }
}
.header {
  background-color: #ffedf3;
  padding: 14px 0px;
  position: fixed;
  width: 85%;
  z-index: 99;
}
.head-icon {
  color: #ff518a !important;
}
.head-padd {
  padding-top: 3.5rem;
}
.css-13xy2my {
  box-shadow: none !important;
}
.css-1bigob2 {
  border-bottom: none !important;
}
.css-q34dxg {
  border-bottom: none !important;
}
:where(.css-f7vrd6).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-f7vrd6).ant-table-wrapper .ant-table-thead > tr > td {
  background-color: transparent !important;
  border-bottom: none !important;
}
:where(.css-f7vrd6).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff518a !important;
  border-color: #ff518a !important;
}
:where(.css-f7vrd6).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background-color: transparent !important;
}
:where(.css-f7vrd6).ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #ff518a !important;
}
:where(.css-f7vrd6).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-f7vrd6).ant-checkbox:not(.ant-checkbox-disabled):hover
  .ant-checkbox-inner {
  border-color: #ff518a !important;
}
:where(.css-f7vrd6).ant-pagination .ant-pagination-item-active {
  border-color: #ff518a !important;
}
.css-13xy2my {
  border: 1px solid #ff518a !important;
}
.css-1omp2b7.Mui-selected {
  background-color: #ffcede !important;
}
:where(.css-f7vrd6).ant-upload-wrapper
  .ant-upload-drag:not(.ant-upload-disabled):hover,
:where(.css-f7vrd6).ant-upload-wrapper
  .ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #ff518a !important;
}
:where(.css-f7vrd6).ant-input-outlined:hover {
  border-color: #ff518a !important;
}
@media (max-width: 768px) {
  .rs-sidenav-nav > .rs-sidenav-item,
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle {
    padding-left: 0px !important;
  }
  .nav-Icons {
    top: 15px;
  }
  .sm-hide {
    display: none;
  }
  .rs-sidenav-default .rs-sidenav-item,
  .rs-sidenav-default .rs-dropdown-toggle,
  .rs-sidenav-default .rs-sidenav-toggle-button {
    margin-top: 10px !important;
  }
  .rs-sidenav-nav .rs-sidenav-item,
  .rs-sidenav-nav .rs-dropdown-toggle,
  .rs-sidenav-nav .rs-dropdown-item,
  .rs-sidenav-nav .rs-dropdown-item-toggle {
    padding-top: 30px !important;
  }
  .head-home h1 {
    font-size: 16px;
  }
  .head-home h2 {
    font-size: 14px;
  }
  .title {
    font-size: 14px;
  }
  .total-box h3 {
    font-size: 14px;
  }
  .home {
    background-color: #ffedf3 !important;
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    width: 125px !important;
  }
  .coupon {
    background-color: #ffcede !important;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    width: 125px !important;
  }
  .banner-img {
    flex-direction: column !important;
  }
  .banner-input {
    width: 100% !important;
  }

  :where(.css-dev-only-do-not-override-f7vrd6).ant-upload-wrapper
    .ant-upload-drag {
    width: 100% !important;
  }

  .toggle {
    width: 100% !important;
  }

  .add-sub {
    width: 100% !important;
  }
  .combine-check {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .input-container {
    flex-direction: column;
    width: 100% !important;
  }
  .input-label,
  .rg-input {
    width: 100% !important;
  }
  .checkbox-combine {
    display: flex;
    width: 100%;
    flex-direction: column !important;
    justify-content: space-evenly !important;
    align-items: start !important;
  }
  .checkbox-combine-1 {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    width: 100%;
  }

  .checkbox-item {
    padding: 0px !important;
  }

  .cancel-but {
    flex-direction: column;
    gap: 20px;
  }
}

.cancel-but {
  display: flex;
  justify-content: space-between;
}

.table-responsive {
  overflow-x: auto;
}

.combine-check {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.checkbox-combine {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  width: 100% !important;
}
.checkbox-combine-1 {
  display: flex;
  justify-content: start;
}
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.css-1bigob2 {
  background-color: #ffedf3 !important;
}

hr {
  background-color: #a7a7a7 !important;
  border: 1px solid #a7a7a7 !important;
}

.sub-but {
  border-bottom: 1.4px dashed black !important;
}

.but-1 {
  width: 50px;
}

:where(
    .css-dev-only-do-not-override-f7vrd6
  ).ant-switch.ant-switch-small.ant-switch-checked
  .ant-switch-inner {
  background-color: #000 !important;
}

.icon {
  font-size: 19px !important;
  font-weight: 600 !important;
}

.add-sub {
  width: 380px;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #ff518a !important;
}

.checkbox-item {
  padding: 0px !important;
}

.but-2 {
  background-color: #ff518a;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 20px;
  padding: 5px 50px;
}

.btn {
  background-color: #ff518a !important;
  color: white !important;
  padding: 5px 30px !important;
  border-color: #ff518a !important;
}

/* .dim-background {
  filter: brightness(30%) !important;
} */

.link {
  text-decoration: none !important;
  color: black;
}

.banner-input {
  width: 885px;
  padding: 8px;
}

.toggle-but {
  border: 1px solid black;
  background-color: white;
  color: grey;
  border-radius: 5px;
  padding: 3px 20px;
  font-family: "Teachers", sans-serif;
}

.toggle {
  width: 82%;
}

.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #ff518a !important;
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 30px;
  width: 430px;
}

.rg-input {
  width: 370px;
  flex: 1;
}

.input-label {
  margin-right: 10px;
  min-width: 100px; /* Adjust this width as needed */
}
.banner-img {
  display: flex;
  width: 100% !important;
}

.banner-input-1 {
  width: 330px;
}

.btn-apply {
  background-color: #ff518a !important;
  color: white !important;
}
.btn-apply-1 {
  background-color: black !important;
  color: white !important;
}

.horizontal {
  border: 1px solid grey !important;
  margin: 12px 0px !important;
}

.view-head {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Teachers", sans-serif;
}
.view-head-1 {
  font-weight: 500;
}
.view-head-2 {
  font-weight: 400;
  color: #484848 !important;
}

.quotation {
  color: black !important;
  text-decoration: 1px underline black !important;
}
.reset-btn {
  color: #ff518a !important;
  margin-left: 10px !important;
  font-weight: 600 !important;
}

:where(.css-dev-only-do-not-override-f7vrd6).ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item {
  display: none !important;
}
.add-banner {
  background-color: #ff518a !important;
  font-weight: 600 !important;
}
.Date{
  width: 265px !important;
  height: 40px !important;
  border: 1px solid #000 !important;
  border-radius: 5px !important;
}
:where(.css-dev-only-do-not-override-f7vrd6).ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
  background-color: #ff518a !important;
  border-color: #ff518a !important;
}
.add-newbtn{
  background-color: transparent !important;
  height: 35px !important;
  color: #ff518a !important;
}
.addtagbtn{
  background-color: #ff518a !important;

}
.tag-inbox{
  width: 70%;
}
.tag-inbox label{
  font-size: 16px;
  font-weight: 500;
  padding: 6px 0px;
}
.tag-inbox input{
  width: 100%;
  height: 40px;
  border: 1px solid #000;
  padding: 6px;
}
.tag-inbox textarea{
  width: 100%;
  border: 1px solid #000;
  padding: 6px;
}
.ql-toolbar.ql-snow{
  border: 1px solid #000 !important;
}
.ql-editor{
  border: 1px solid #000 !important;
}
.sub-navlinks {
  position: absolute;
  /* transform: translateY(210px); */
  z-index: 99;
  display: flex;
  left: 200px;
  background-color: #ffedf3;
  padding: 1.5rem;
}
.sub-navlinks ul li {
  list-style-type: none;
}
.sub-navlinks ul li a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.ant-spin-dot i {
  background-color: #ff518a !important;
}